export { default as anime } from 'animejs/lib/anime.es.js';
import ScrollReveal from 'scrollreveal';

const sr = ScrollReveal();

export var jquery = require("jquery");
window.$ = window.jQuery = jquery;

import { Modal } from 'bootstrap';
export const bootstrap = { Modal };

export { default as Lottie } from '@lottiefiles/lottie-player/dist/tgs-player';

(function($){

  //- Loder

  const select = (el, all = false) => {
    el = el.trim()
    if (all) {
      return [...document.querySelectorAll(el)]
    } else {
      return document.querySelector(el)
    }
  }

  let preloader = select('.preloader'); 
  if (preloader) {
    window.addEventListener('load', () => {
      preloader.classList.add("fade-out");
      setTimeout(function() {
        preloader.classList.add("d-none");
      }, 1000);
    });
  }

  //- Scroll Reveal

  window.onload = () => {
    ScrollReveal().reveal('.fade-in', { easing: "ease-in" });

    // Modal
    let offCanvasModal = new bootstrap.Modal(document.getElementById('offCanvas'));

    document.querySelectorAll('a').forEach((anchor) => {
      let href = anchor.getAttribute('href');
      // Check if href starts with '#'
      if (href.startsWith('#')) {
          anchor.addEventListener('click', function (e) {
              e.preventDefault();

              offCanvasModal.hide();

              // Make sure that href is a valid ID selector.
              if (href.length > 1) { 
                  let target = document.querySelector(href);
                  // Ensure that the target element exists
                  if (target) { 
                      window.scrollTo({
                          top: target.offsetTop,
                          behavior: 'smooth'
                      });
                  }
              }
          });
      }
      // If it's a link to another page, simply close the modal.
      else if (href.startsWith('/')) {
          anchor.addEventListener('click', function (e) {
              offCanvasModal.hide();
          });
      }
    });

    document.querySelectorAll('a').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
          let href = this.getAttribute('href');
          // Check if href starts with '#'
          if (href.startsWith('#')) {
              e.preventDefault();

              offCanvasModal.hide();

              // Make sure that href is a valid ID selector.
              if (href.length > 1) { 
                  let target = document.querySelector(href);
                  // Ensure that the target element exists
                  if (target) { 
                      window.scrollTo({
                          top: target.offsetTop,
                          behavior: 'smooth'
                      });
                  }
              }
          }
          // If it's a link to another page, simply close the modal.
          else if (href.startsWith('/')) {
              offCanvasModal.hide();
          }
      });
    });
  };

})(jQuery);